<template>
  <b-container
    fluid
    class="registration wrapper auth-layout"
    style="height: 100vh"
  >
    <div class="registration-form">
      <b-row style="height: inherit" class="auth-layout">
        <b-col
          md="6"
          offset-md="3"
          style="display: flex; justify-content: center; align-items: center"
        >
          <b-card no-body border-variant="0">
            <slot></slot>
            <b-card-body>
              <b-alert v-if="error" show variant="danger">
                <template v-if="errorMessage">{{ errorMessage }}</template>
                <template v-else>
                  Виникла помилка при отриманні даних, спробуйте пройти
                  верифікацію через деякий час.
                </template>
              </b-alert>

              <b-card-text v-if="!show && !error">
                <b-spinner small label="Small Spinner" type="grow"></b-spinner>
                Зачекайте, отримуємо дані ідентифікації
              </b-card-text>

              <b-card-text v-if="show && !error">
                Дані {{ form }} підтверджено. <br /><br />
                <i>Дякуємо, що скористались нашим сервісом!</i>
              </b-card-text>
            </b-card-body>

            <b-card-footer footer-bg-variant="white" footer-border-variant="0">
              <b-container>
                <b-row>
                  <b-col md="6">
                    <b-img
                      center
                      src="/images/aml-logo.svg"
                      style="width: 40%"
                    />
                  </b-col>
                  <b-col md="6">
                    <b-img center src="/images/isei.png" style="width: 48%" />
                  </b-col>
                </b-row>
              </b-container>
            </b-card-footer>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <div class="registration-video">
      <video
        class="aml-video"
        src="/video/aml-register.mp4"
        autoplay
        loop
      ></video>
    </div>
  </b-container>
</template>

<script>
import api from "../api/api";

export default {
  name: "Dyakuyu",
  props: {
    tenantAlias: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: {},
      show: false,
      error: false,
      errorMessage: "",
    };
  },
  beforeMount() {
    this.verification();
  },
  methods: {
    verification() {
      api
        .counterpartVerificationVerify({
          code: this.$route.query.code,
          state: this.$route.query.state,
          alias: this.tenantAlias,
        })
        .then(({ data }) => {
          this.form = data;
          this.show = true;
        })
        .catch(({ response }) => {
          this.errorMessage = response.data.message;
          this.error = true;
        });
    },
  },
};
</script>
